import type { ShouldRevalidateFunction } from '@remix-run/react';
import { useLoaderData } from '@remix-run/react';
import type { LoaderArgs, V2_MetaFunction } from '@remix-run/cloudflare';

import { defaultDescription, defaultTitle } from '../shared/config/const';
import type { IUrlDash, IUrlHLS, IUrlMP4 } from '../shared/types/url';

import { feedModel } from 'src/entities/feed';

import { Page } from 'src/pages/hot';
import { useEffect } from 'react';

export const meta: V2_MetaFunction<typeof loader> = ({ data }) => {
  const title = data?.title || defaultTitle;
  const description = data?.description || defaultDescription;
  
  return [
    { name: 'charset', content: 'utf-8' },
    { name: 'viewport', content: 'initial-scale=1, viewport-fit=cover, width=device-width'},
    { name: 'keywords', content: 'tiktok, porn, sex, hot, real, nsfw, reddit, amateur, mobile, tikthots' },
    { name: 'rating', content: 'adult' },
    { name: 'rating', content: 'RTA-5042-1996-1400-1577-RTA' },
    { name: 'theme-color', content: '#000000' },
    { name: 'referrer', content: 'unsafe-url' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' },
    { title },
    { name: 'description', content: description },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:video', content: data?.h264Url || '' },
    { property: 'og:video:height', content: '1920' },
    { property: 'og:video:width', content: '1080' },
    { property: 'og:type', content: 'video' },
    { property: 'og:video:type', content: 'video/mp4' },
    { property: 'og:image', content: data?.previewUrl || '' },
    { property: 'twitter:site', content: 'Fupp.app' },
    { property: 'twitter:title', content: title },
    { property: 'twitter:url', content: `https://fupp.app/hot/${data?.id}` },
    { property: 'twitter:description', content: description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:image', content: data?.previewUrl || '' },
    { property: 'twitter:height', content: '1920' },
    { property: 'twitter:width', content: '1080' },
    ...(data?.tags?.map((x) => ({ property: 'og:tags', content: x.name })) || [])
  ];
};

export const shouldRevalidate: ShouldRevalidateFunction = () => false;


export const loader = async ({ params }: LoaderArgs) => {
  const id = params.id;
  if (!id) {
    return null;
  }

  const response = await fetch(`https://api.fupp.app/api/videos/${id}`);

  const data = await response.json();

  if(!data || typeof data !== 'object' || !('data' in data) || !data.data){
    return null;
  }

  const result = feedModel.VideoResponse.check(data);

  return {
    id: result.data.id,
    title: result.data.title!,
    description: result.data.description,
    hlsUrl: result.data.m3u8_url as IUrlHLS,
    dashUrl: result.data.mpd_url as IUrlDash,
    // mp4Url: result.data.video_url_sd || result.data.video_url,
    // mp4Url: result.data.video_url as IUrlMP4,
    h264Url: result.data.h264_video_url || result.data.video_url || null,
    hevcUrl: result.data.hevc_video_url || null,
    av1Url: result.data.av1_video_url || null,
    type: 'video' as const,
    tags: result.data.tags,
    previewUrl: (result.data.h264_video_url || result.data.video_url)?.replace('.mp4', '.poster.jpg'),
  };
};



export default function () {
  const data = useLoaderData<typeof loader>();

  useEffect(() => {
    if(data){
      feedModel.setVideo(data);
    }
  }, [data]);

  useEffect(() => {
    if(typeof window !== 'undefined'){
      feedModel.setInit();
    }
  }, []);

  return (
    <Page firstVideo={data} />
  );
};
